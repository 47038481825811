/* .str-chat__avatar-fallback {
    visibility: hidden;
} */

/* .enableCareNavigationPreview {
    padding: 25px;
}

.enableCareNavigationMore {
    margin: 45px;

}

.box {
    padding: 13px;
    text-align: justify;
    margin-top: 50px;
}


.button {
    width: 80%;
    border: none;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:#FFFFFF;
    background-color:#49b3f0;
    transition: all 0.2s;
}


.buttonSmall {
    width: 80%;
    color: black;
    border: none;
    background-color: #eaeaea;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    transition: all 0.2s;
}

.seeMoreBox {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.acceptBox {
    width: 100%;
    margin-top: 15px;
    text-align: center;
}

.closeMoreBox {
    width: 100%;
    margin-top: 15px;
    text-align: center;
}


.button.accept {
    background-color: #303D50;
} */

.centeredView{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.centeredViewImage{
    display: flex;
    width: 20px;
    height: 20px;
}

/* .button{
    background-color:#4095c6;
}

@media all and (max-width:30em) {
     a.button {
    display:block;
    margin:0.2em auto;
    }
} */

